/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect,useState } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import Aaxios from 'src/utils/Aaxios';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  colors
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
} from 'react-feather';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import CommuteIcon from '@material-ui/icons/Commute';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

const navConfig = [
  {
    subheader: 'Reports',
    items: [
      
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      },
      {
        title: 'Dashboard Alternative',
        icon: BarChartIcon,
        href: '/app/reports/dashboard-alternative'
      },
      {
        title: 'Daily Route Report',
        icon: AssignmentIcon ,
        href: '/app/Operator/Report'
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Vehicles',
        icon: CommuteIcon,
        items: [
          {
            title: 'Vehicles Route Input',
            href:'/app/Operator/input'
          }
        ]
      },
      {
        title: 'Projects Platform',
        href: '/app/projects',
        icon: BriefcaseIcon,
        items: [
          {
            title: 'Overview',
            href: '/app/projects/overview'
          },
          {
            title: 'Browse Projects',
            href: '/app/projects/browse'
          },
          {
            title: 'Create Project',
            href: '/app/projects/create'
          },
          {
            title: 'View Project',
            href: '/app/projects/1'
          }
        ]
      },
      {
        title: 'Social Platform',
        href: '/app/social',
        icon: ShareIcon,
        items: [
          {
            title: 'Profile',
            href: '/app/social/profile'
          },
          {
            title: 'Feed',
            href: '/app/social/feed'
          }
        ]
      },
      {
        title: 'Employee',
        icon: PersonIcon,
        items: [
          {
            title: 'Employee Data',
            href: '/app/Employee/List',
          },
          {
            title: 'Employee Register',
            href:'/app/Employee/Register'
          }
        ]
      },
      
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },
      {
        title: 'Chat',
        href: '/app/chat',
        icon: MessageCircleIcon,
        info: () => (
          <Chip
            color="secondary"
            size="small"
            label="Updated"
          />
        )
      },
      {
        title: 'Calendar',
        href: '/app/calendar',
        icon: CalendarIcon
      },
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Customers',
        icon: UsersIcon,
        href: '/app/management/customers',
        items: [
          {
            title: 'List Customers',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer',
            href: '/app/management/customers/1'
          },
          {
            title: 'Edit Customer',
            href: '/app/management/customers/1/edit'
          }
        ]
      },
      {
        title: 'Products',
        icon: ShoppingCartIcon,
        href: '/app/management/products',
        items: [
          {
            title: 'List Products',
            href: '/app/management/products'
          },
          {
            title: 'Create Product',
            href: '/app/management/products/create'
          }
        ]
      },
      {
        title: 'Orders',
        icon: FolderIcon,
        href: '/app/management/orders',
        items: [
          {
            title: 'List Orders',
            href: '/app/management/orders'
          },
          {
            title: 'View Order',
            href: '/app/management/orders/1'
          }
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
          {
            title: 'View Invoice',
            href: '/app/management/invoices/1'
          }
        ]
      }
    ]
  },

  {
    subheader: 'Auth',
    items: [
      {
        title: 'Login',
        href: '/login-unprotected',
        icon: LockIcon
      },
      {
        title: 'Register',
        href: '/register-unprotected',
        icon: UserPlusIcon
      },
      {
        title: 'Login: Guest Protected',
        href: '/login',
        icon: ShieldIcon
      },
      {
        title: 'Register: Guest Protected',
        href: '/register',
        icon: ShieldIcon
      }
    ]
  },
  {
    subheader: 'Pages',
    href: '/app/pages',
    items: [
      {
        title: 'Account',
        href: '/app/account',
        icon: UserIcon
      },
      {
        title: 'Error',
        href: '/404',
        icon: AlertCircleIcon
      },
      {
        title: 'Pricing',
        href: '/pricing',
        icon: DollarSignIcon
      }
    ]
  },
 
  {
    subheader: 'Extra',
    items: [
      {
        title: 'Charts',
        href: '/app/extra/charts',
        icon: BarChartIcon,
        items: [
          {
            title: 'Apex Charts',
            href: '/app/extra/charts/apex'
          }
        ]
      },
      {
        title: 'Forms',
        href: '/app/extra/forms',
        icon: EditIcon,
        items: [
          {
            title: 'Formik',
            href: '/app/extra/forms/formik'
          },
          {
            title: 'Redux Forms',
            href: '/app/extra/forms/redux'
          },
        ]
      },
      {
        title: 'Editors',
        href: '/app/extra/editors',
        icon: LayoutIcon,
        items: [
          {
            title: 'DraftJS Editor',
            href: '/app/extra/editors/draft-js'
          },
          {
            title: 'Quill Editor',
            href: '/app/extra/editors/quill'
          }
        ]
      }
     
    ]
  }
   
];


const Dashbaord=[
  {
    subheader: 'Dashbaord',
    items: []

  }

];

const Accounting=[
  {
    subheader: 'Accounting',
    items: []
  }
];


const OperationControl  =[

  {
    subheader: 'Operation Control',
    items: [
      {
        title: 'Daily Route Report',
        icon: AssignmentIcon ,
        href: '/app/Operator/Report'
        
      },
      {
        title: 'Daily Route Input',
        icon: CommuteIcon,
        items: [
          {
            title: 'Vehicles Route Input',
            href:'/app/Operator/input'
          }
        ]
      },
      {
        title: 'Add Incharge customer',
        icon: AssignmentIcon ,
        href: '/app/Operator/incharge'
        
      },
      {
        
        title: 'Add Customer Vehicles',
        icon: CommuteIcon ,
        href: '/app/customers/vehicles'
        
      },
    ]
  }
];


function valAlert(qty)
{
  
return (
  (qty!=0)&&<Chip
  color="secondary"
  size="small"
  label={qty}
  style={{backgroundColor:'#DB4437'}}
/>
);
  
}

function Administration (numval,numval2){
  return(
  [
  {
  subheader:'Administration',
  items: [
    {
      title: 'Manage Vehicles',
      icon: CommuteIcon,
      info: () =>valAlert(numval2),
      items: [
        {
          title: 'Vehicles Data',
          href: '/app/VehiclesList/List',
          info: () =>valAlert(numval2),
        },
        {
          title: 'Vehicles Register',
          href:'/app/Vehicles/Register'
        }
      ]
    },
    {
      title: 'Manage Oil',
      icon: AccountBalanceWalletIcon,
      items: [
        {
          title: 'Oil Data List',
          href: '/app/OilCard/List',
        },
        {
          title: 'Oil Register',
          href:'/app/OilCard/Register'
        },
        {
          title: 'Oil Fill Record',
          href:'/app/FuelFill/Record'
        },
        {
          title: 'Oil Fill Record Complete',
          href:'/app/FuelFill/Complete'
        }
      ]
    }

  ],
  }]
  );
}

function AdministrationHR (numval,numval2){
  return(
  [
  {
  subheader:'Administration HR',
  items: [
    {
      title: 'Manage  Employee',
      icon: PersonIcon,
      info: () =>valAlert(numval),
      items: [
        {
          title: 'Employee Data',
          href: '/app/Employee/List',
          info: () =>valAlert(numval),
        
        },
        {
          title: 'Employee Register',
          href:'/app/Employee/Register'
        }
      ]
    },
    {
      title: 'Manage User',
      icon: PersonIcon,
      items: [
        {
          title: 'User Data',
          href: '/app/User/List',
        },
        {
          title: 'User Register',
          href:'/app/User/Register'
        }
      ]
    },


  ],
  }]
  );
}


const Marketing=[
  {
    subheader: 'Marketing',
    items: [
      
      {
        title: 'Customers',
        icon: PieChartIcon,
        href: '/app/customers',
        items: [
          
          {
            title: 'Customers Data ',
            href: '/app/customers/'
          },
          {
            title: 'Customer Register',
            href: '/app/customers/register'
          }
        ]
      }
      /*
      ,
      {
        title: 'Customers Route',
        icon: PieChartIcon,
        href: '/app/reports/dashboard',
        items: [
          {
            title: 'List Customers Route',
            href: '/app/management/customers'
          },
          {
            title: 'View Customer Route',
            href: '/app/management/customers/1'
          },
          {
            title: 'New Customer Route',
            href: '/app/management/customers/1/edit'
          }
        ]
      }
      */
    ]
  }

];

const  UserProfile=[
  {
    subheader: 'User',
      items: [
        {
          title: 'User Account',
          icon: UserIcon,
          href: '/app/user/account'
        },
        {
          title: 'Log out',
          icon: LockIcon ,
          href: '/login-unprotected'
        }
      ]
    }

];

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}



function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  title: {
    marginRight: 'auto',
    backgroundColor: colors.red[600],
  }

}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);
  const [Aemployee,setAemployee]= useState(0)
  const [Avehicles,setAvehicles]= useState(0)

  useEffect(() => {

 
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    Aaxios.get('api/alert/employee')
     .then((response) => {
      if(response.data.QTY)setAemployee(response.data.QTY)
     }

     , (error) => {
      console.log(error);
     
    });

    Aaxios.get('api/alert/vehicles')
    .then((response) => {
      if(response.data.QTY)setAvehicles(response.data.QTY)
      
    }

    , (error) => {
     console.log(error);
    
   });

    // eslint-disable-next-line
  }, [location.pathname]);

  function checkmenu(user)
{


  
   if(user.role=="Marketing")
   {
     return Dashbaord.concat((Marketing.concat(UserProfile)))
   }
   else if(user.role=="Operation")
   {
    return Dashbaord.concat(OperationControl.concat(UserProfile))

   }
   else if(user.role=="Account")
   {
    return Dashbaord.concat(Accounting.concat(UserProfile))

   }
   else if(user.role=="Admin")
   {
    return Dashbaord.concat(Administration(Aemployee,Avehicles).concat(UserProfile))

   }
   else if(user.role=="AdminHR")
   {
    return Dashbaord.concat(AdministrationHR(Aemployee,Avehicles).concat(UserProfile))

   }
   else
   {

    var adminisrator=Marketing.concat(OperationControl)
    adminisrator= adminisrator.concat(Administration(Aemployee,Avehicles))
    adminisrator= adminisrator.concat(AdministrationHR(Aemployee,Avehicles))
    adminisrator= adminisrator.concat(Accounting)

    return Dashbaord.concat(adminisrator.concat(UserProfile));

   }

}

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            {/*<RouterLink to="/app/account">*/}
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            {/*</RouterLink>*/}
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {`${user.firstName} ${user.lastName}`}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.bio}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>

          
          {
         
         checkmenu(user).map((config) => (
            <List
              key={config.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({ items: config.items, pathname: location.pathname })}
            </List>
          ))}
        </Box>
        <Divider />
        {/*
        <Box p={2}>
          <Box
            p={2}
            borderRadius="borderRadius"
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>
        */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
