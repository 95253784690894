import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import Aaxios from 'src/utils/Aaxios';
import maxios from 'axios';
import hash from 'react';
class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    Aaxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);

          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithUsernameAndPassword = (username, password) => new Promise((resolve, reject) => {
    var crypto = require('crypto');
    var sha512 = crypto.createHash('sha512').update(password).digest("hex");
    const payload = {
      username: username,
      password: sha512
    };
    Aaxios.post('/api/user/login',JSON.stringify(payload))
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
   axios.post('/api/account/login',{email,password})
      .then((response) => {
        if (response.data.user) {
          this.setSession(response.data.accessToken);
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get('/api/user/me')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginInWithTokenuser = () => new Promise((resolve, reject) => {

    const Token=localStorage.getItem('accessToken');

    const payload = {
      Token: Token
    };
    Aaxios.post('/api/user/me',JSON.stringify(payload))
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })


  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
     // Aaxios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    var result =decoded.exp > currentTime;
    return result;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
