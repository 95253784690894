import _ from 'lodash';
import jwt from 'jsonwebtoken';
import mock from 'src/utils/mock';

const JWT_SECRET = 'devias-top-secret-key';
const JWT_EXPIRES_IN = '2 days';

const db = {
  user: {
    id: '5e86809283e28b96d2d38537',
    //avatar: '/static/images/avatars/avatar_1.png',
    avatar: '',
    bio: 'Operation Control Staff',
    canHire: false,
    country: 'USA',
    email: 'katarina.smith@devias.io',
    username: 'Guest',
    password: '1234',
    firstName: 'Kotchakorn Saengchan',
    isPublic: true,
    lastName: '',
    phone: '+40 777666555',
    role: 'admin',
    state: 'New York',
    timezone: '4:32PM (GMT-4)'
  },
  OP: {
    id: '5e86809283e28b96d2d38537',
    //avatar: '/static/images/avatars/avatar_1.png',
    avatar: '',
    bio: 'Operation Control Staff',
    canHire: false,
    country: 'USA',
    email: 'katarina.smith@devias.io',
    username: 'Operation',
    password: 'op1234',
    firstName: 'Kotchakorn Saengchan',
    isPublic: true,
    lastName: '',
    phone: '+40 777666555',
    role: 'Operation',
    state: 'New York',
    timezone: '4:32PM (GMT-4)'
  },
  MK: {
    id: '5e86809283e28b96d2d38537',
    //avatar: '/static/images/avatars/avatar_1.png',
    avatar: '',
    bio: 'Marketing Control Staff',
    canHire: false,
    country: 'USA',
    email: 'katarina.smith@devias.io',
    username: 'Marketing',
    password: 'mk1234',
    firstName: 'Soysunee Pannarach',
    isPublic: true,
    lastName: '',
    phone: '+40 777666555',
    role: 'Marketing',
    state: 'New York',
    timezone: '4:32PM (GMT-4)'
  }

};


mock.onPost('/api/account/login').reply((config) => {
  const { email, password } = JSON.parse(config.data);
  var accessToken="";
  const { user,OP,MK } = db;
  if (email == 'Guest' || password == '1234') {
    accessToken = jwt.sign(
      { id: user.id,
        role: user.role
      },
      JWT_SECRET,
      { expiresIn: JWT_EXPIRES_IN }
    );
    return [200, { user, accessToken }];
  }
  else if(email == 'Operation' || password == 'op1234')
  {
    accessToken = jwt.sign(
      { id: OP.id,
        role: OP.role
      },
      JWT_SECRET,
      { expiresIn: JWT_EXPIRES_IN }
    );
    let user=OP;
    return [200, { user, accessToken }];
  }
  else if(email == 'Marketing' || password == 'mk1234')
  {
    accessToken = jwt.sign(
      { id: MK.id,
        role: MK.role
      },
      JWT_SECRET,
      { expiresIn: JWT_EXPIRES_IN }
    );
    let user=MK;
    return [200, { user, accessToken }];
  }
  else
  {
    return [400, { message: 'Please check your email and password' }];
  }
 

  

 
});

mock.onGet('/api/account/me').reply((config) => {
  const { Authorization } = config.headers;

  if (!Authorization) {
    return [401, { message: 'Authorization token missing' }];
  }

  try {
    const accessToken = Authorization.split(' ')[1];

    const { id } = jwt.verify(accessToken, JWT_SECRET);
    const { role } = jwt.verify(accessToken, JWT_SECRET);

    if (id !== db.user.id) {
      return [401, { message: 'Invalid authorization token' }];
    }

    if(role=='Operation')
    {
      return [200, { user: db.OP}];
    }
    else if(role=='Marketing')
    {
      return [200, { user: db.MK}];
    }
    else
    {
      return [200, { user: db.user }];
    }
   
  } catch (error) {
    return [401, { message: 'Invalid authorization token' }];
  }
});

mock.onPost('/api/account/profile').reply((request) => {
  const { update } = JSON.parse(request.data);

  _.assign(db.user, update);

  return [200, { user: db.user }];
});

mock.onGet('/api/account/settings').reply(200, {
  settings: {}
});

mock.onGet('/api/account/subscription').reply(200, {
  subscription: {
    name: 'Freelancer',
    price: '5',
    currency: '$',
    proposalsLeft: 12,
    templatesLeft: 5,
    invitesLeft: 24,
    adsLeft: 10,
    hasAnalytics: true,
    hasEmailAlerts: true
  }
});
