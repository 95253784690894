import axios from 'axios';

const instance = axios.create(
    {
       baseURL: 'https://kpattgroup.com',
       //baseURL: 'http://localhost/'
    }
);


export default instance;
