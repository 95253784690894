import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Typography,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {

    justifyContent:"center",

    [theme.breakpoints.down('md')]: 
    {
      paddingTop: 60,
      paddingBottom: 60

    }

  },
  detial:{

    marginTop:theme.spacing(10),
  },
  link:
  {
    maxWidth: '50%',
    marginTop:theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  image: {
    

      maxWidth: '25%',
      display: 'block',
      marginTop:theme.spacing(5),
      marginBottom: theme.spacing(5)
   
  },
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container
      
      >
        <Grid
          container
          spacing={3}
        >
        <Grid
            item
            xs={12}
            md={12}
            container
            spacing={5}
            alignItems="center"
            justify="center"
          >
          
      
                <img
                  className={classes.image}
                  alt="Shapes"
                  src="/static/logo_2.svg"
                />
          
      


          </Grid>
          <Grid
            item
            xs={6}
            className={classes.detial}
          >

      
          <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography
                variant="h1"
                color="textPrimary"
              >
                บริษัท กุ้งพัฒนา ทรานสปอร์ต จำกัด
              </Typography>
              <Box mt={2}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                  254 ถนนบางแสนล่าง ตำบลแสนสุข
                  อำเภอเมือง ชลบุรี จังหวัดชลบุรี 20130
                 </Typography>
                 <Typography
                  variant="body1"
                  color="textSecondary"
                >
                   
                  เลขประจำตัวผู้เสียภาษี
                  0205556035227

                  </Typography>
              </Box>
            </Box>
            </Grid>
            <Grid
            item
            xs={6}
            className={classes.detial}
          >

      
          <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
            <Typography
                variant="h1"
                color="textPrimary"
              >
                บริษัท กุ้งพัฒนา โลจิสติกส์ จำกัด 
              </Typography>
              <Box mt={2}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
                 254/1 ถนนบางแสนล่าง ตำบลแสนสุข
อำเภอเมือง จังหวัดชลบุรี 20130


                 </Typography>
                 <Typography
                  variant="body1"
                  color="textSecondary"
                >
                   
                   เลขประจำตัวผู้เสียภาษี 
                   0205553010819
                  </Typography>
              </Box>
            </Box>
            </Grid>
         
         
            <Grid
            item
            xs={12}
            md={12}
            container
            spacing={5}
            alignItems="center"
            justify="center"
            className={classes.detial}
          >

      
          <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
              
            >
            <Typography
                variant="h1"
                color="textPrimary"
              >
                บริษัท เค-พลัส บัสแอนด์บอดี้ จำกัด

              </Typography>
              <Box mt={2}>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
   21/5 หมู่ที่ 6 ตำบลห้วยกะปิ อำเภอเมืองชลบุรี 
จังหวัดชลบุรี  20000



                 </Typography>
                 <Typography
                  variant="body1"
                  color="textSecondary"
                >
                   
                   เลขประจำตัวผู้เสียภาษี 0205562035462 

                  </Typography>
              </Box>
            </Box>
            </Grid>
    
          <Grid
            item
            xs={12}
            md={12}
            container
            spacing={5}
            alignItems="center"
            justify="center"
            className={classes.detial}
          >
            <Button
                className={classes.link}
                fullWidth
                color="secondary"
                component={RouterLink}
                to="/app"
                variant="contained"
                size="large"
              >
                เข้าสู่ระบบ
                Log in
          </Button>
          </Grid>
        </Grid>

      </Container>

      
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
